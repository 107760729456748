html {
  margin: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

body {
  max-width: 1298px;
  background: url("header-bg.f90c75b5.svg") 50% -10px no-repeat;
  margin: 0 auto;
  padding: 0 35px;
  font-family: Roboto, sans-serif;
  transition: background-color .2s ease-in;
}

body.theme-white {
  color: #111;
  background-color: #fff;
}

body.theme-dark {
  color: #fff;
  background-color: #000;
}

.theme-white h1, .theme-white h2, .theme-white h3, .theme-white h4, .theme-white a, .theme-white .site-header .logo, .theme-white #theme-btn, .theme-white nav a, .theme-white nav .menu-icon, .theme-white .hero button, .theme-white .home-section .section-title .title-with-icon h2 {
  color: #111;
}

.theme-white .home-section .section-title .section-subtitle {
  color: #333;
}

.theme-white .recent-searches .recent-search-item {
  background-color: #fff3;
  border: 1px solid #eee;
}

.theme-white .recent-searches .recent-search-item .recent-search-item-title .recent-search-item-badges {
  color: #111111bf;
}

.theme-white .main-content .card {
  background-color: #ffffffd9;
  box-shadow: 0 2px 8px #00000026;
}

.theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark a, .theme-dark .site-header .logo, .theme-dark #theme-btn, .theme-dark nav a, .theme-dark nav .menu-icon, .theme-dark .hero button, .theme-dark .home-section .section-title .title-with-icon h2 {
  color: #fff;
}

.theme-dark .home-section .section-title .section-subtitle {
  color: #ddd;
}

.theme-dark .recent-searches .recent-search-item {
  background-color: #0003;
  border: 1px solid #333;
}

.theme-dark .recent-searches .recent-search-item .recent-search-item-title .recent-search-item-badges {
  color: #ffffffbf;
}

.theme-dark .main-content .card {
  background-color: #111;
  box-shadow: 0 2px 8px #00000040;
}

.theme-dark .pagination a {
  border: 1px solid #999;
  color: #ff4365 !important;
  color: #ddd !important;
}

.theme-dark .pagination a.current {
  border: 1px solid #fff;
  color: #fff !important;
}

h1, h2 {
  font-family: Open Sans, sans-serif;
  font-weight: 700;
}

.t-center {
  text-align: center;
}

.icon {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.site-header {
  height: 38px;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 32px;
  padding: 0 20px;
  display: flex;
}

.site-header .logo {
  flex-grow: 100;
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
}

.site-header #theme-btn {
  cursor: pointer;
  background: none;
  border: none;
  padding: 8px;
}

.site-header nav {
  flex-grow: 0;
}

.site-header nav a {
  box-sizing: border-box;
  margin: 8px 28px;
  font-size: 16px;
  text-decoration: none;
  transition: color .2s ease-in;
}

.site-header nav a:last-child {
  margin-right: 0;
}

.site-header nav a.outline {
  border: 1px solid #ff4365;
  border-radius: 10px;
  padding: 8px 18px;
}

.site-header nav a:hover, .site-header nav a:active {
  color: #ff4365;
}

.site-header nav .menu-icon, .site-header nav .close-menu-icon {
  cursor: pointer;
  background: none;
  border: none;
  padding: 4px;
  font-size: 34px;
  display: none;
}

.hero {
  max-width: 620px;
  flex-direction: column;
  margin: 92px auto;
  padding: 0 20px;
  display: flex;
}

.hero h1 {
  text-align: center;
  margin-bottom: 54px;
  font-size: 32px;
  line-height: 48px;
}

#searchbox {
  background: #fff;
  border-radius: 10px;
  outline: 2px solid #0000;
  transition: outline .2s ease-in, box-shadow .2s ease-in;
  position: relative;
  box-shadow: 0 8px 20px #0000001a;
}

#searchbox .search-field {
  flex-direction: row;
  display: flex;
}

#searchbox:hover {
  outline: 2px solid #ff436580;
  box-shadow: 0 8px 20px #0000001a;
}

#searchbox:hover #search-result {
  outline: 2px solid #ff436580;
}

#searchbox input {
  box-sizing: border-box;
  min-width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 18px 48px 18px 24px;
  font-size: 16px;
}

#searchbox input::placeholder {
  opacity: .7;
}

#searchbox button {
  vertical-align: middle;
  cursor: pointer;
  background: none;
  border: none;
  margin-left: -48px;
  color: #000 !important;
}

#searchbox #search-result {
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  background: #fff;
  border-radius: 0 0 10px 10px;
  transition: outline .2s ease-in, box-shadow .2s ease-in;
  position: absolute;
  box-shadow: 0 8px 20px #00000073;
}

#searchbox #search-result .search-item {
  color: #000;
  border-bottom: 1px dashed #ddd;
  padding: 20px 20px 10px;
  text-decoration: none;
  display: block;
}

#searchbox #search-result .search-item:last-child {
  border-bottom: none;
}

#searchbox #search-result p {
  color: #000;
  margin: 0 0 5px;
}

#searchbox #search-result p.municipio-uf {
  opacity: .8;
  font-size: 13px;
}

#searchbox.with-results {
  border-radius: 10px 10px 0 0;
}

#searchbox.no-results #search-result {
  display: none;
}

.home-section {
  margin-bottom: 12px;
}

.home-section .section-title {
  flex-direction: column;
  margin-bottom: 16px;
  padding: 0 20px;
  display: flex;
}

.home-section .section-title .title-with-icon {
  align-items: center;
  gap: 10px;
  display: flex;
}

.home-section .section-title .title-with-icon h2 {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.home-section .section-title .section-subtitle {
  margin: 10px 0;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
}

.home-section .content {
  padding: 0 20px;
}

.recent-searches {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 28px;
  margin-bottom: 36px;
  display: flex;
}

.recent-searches .recent-search-item {
  box-sizing: border-box;
  min-width: 45%;
  max-width: 390px;
  padding: 16px;
}

.recent-searches .recent-search-item .recent-search-item-title {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.recent-searches .recent-search-item .recent-search-item-title h3 {
  font-size: 14px;
}

.recent-searches .recent-search-item .recent-search-item-title h3 a {
  text-decoration: none;
}

.recent-searches .recent-search-item .recent-search-item-title .recent-search-item-badges {
  font-size: 12px;
}

.recent-searches .recent-search-item .recent-search-item-title .recent-search-item-badges span {
  padding: 2px 4px;
  display: inline-block;
}

.recent-searches .recent-search-item p {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 8px 0 0;
  font-size: 14px;
  overflow: hidden;
}

.flex {
  display: flex;
}

.f-row {
  flex-direction: row;
  column-gap: 48px;
}

.f-column {
  flex-direction: column;
  row-gap: 24px;
}

.stats-section img {
  height: auto;
  width: 100%;
}

.stats {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.stats .stats-number {
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  display: block;
}

.github-repos {
  max-width: 660px;
  flex-wrap: wrap;
  column-gap: 12px;
  margin-top: -12px;
  display: flex;
}

.github-repos .github-repo {
  border-bottom: 1px dotted #999;
  flex-grow: 2;
  padding: 20px;
  position: relative;
}

.github-repos .github-repo h3 {
  margin: 2px 0 8px;
}

.github-repos .github-repo h3 a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.github-repos .github-repo h3 a:hover {
  text-decoration: underline;
}

.github-repos .github-repo .lang:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 100%;
  margin-right: 6px;
  display: inline-block;
}

.github-repos .github-repo .lang.rs:before {
  background-color: #c28f0a;
}

.github-repos .github-repo .lang.py:before {
  background-color: #1d67d6;
}

.github-repos .github-repo .lang.js:before {
  background-color: #fff500;
}

.github-repos .github-repo .lang.go:before {
  background-color: #0e9cff;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.main-content h1, .main-content h2, .main-content h3 {
  font-weight: 600;
}

.main-content h1 {
  font-size: 18px;
}

.main-content h2 {
  margin-bottom: 8px;
  font-size: 22px;
}

.main-content h3 {
  font-size: 18px;
}

.main-content h4 {
  font-size: 16px;
  font-weight: 600;
}

.main-content p, .main-content ul {
  margin-top: 8px;
  margin-bottom: 18px;
  font-size: 15px;
  line-height: 1.3em;
}

.main-content ul li {
  margin-bottom: 8px;
}

.main-content .card {
  box-sizing: border-box;
  gap: 48px;
  margin-bottom: 16px;
  padding: 36px;
  display: flex;
}

.main-content .card div {
  flex: 1 1 0;
}

.main-content .card.listing-page {
  flex-direction: column;
  gap: 18px;
}

.main-content .example {
  max-height: 300px;
  min-width: 100%;
  color: #fff;
  max-width: 400px;
  background: #111;
  padding: 16px;
  overflow: scroll;
}

.listing-content {
  grid-template-rows: repeat(8, auto);
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  display: grid;
}

.listing-content .listing-item a {
  font-weight: 500;
}

.listing-content .listing-item p {
  margin: 0;
}

.breadcrumb ol {
  margin: 0 0 4px;
  padding-left: 0;
  list-style: none inside;
  display: inline-block;
}

.breadcrumb li {
  padding: 0;
  display: inline-block;
}

.breadcrumb li:after {
  content: " ›";
}

.breadcrumb li:last-child:after {
  content: "";
}

.breadcrumb li a {
  min-width: 48px;
  text-align: center;
  min-height: 48px;
  box-sizing: border-box;
  padding: 8px;
  text-decoration: none;
  display: inline-block;
}

.pagination a {
  border: 1px solid #ddd;
  padding: 8px 11px;
  text-decoration: none;
  display: inline-block;
  color: #ff4365 !important;
}

.pagination a.current {
  border: 1px solid #999;
  color: #111 !important;
}

.uf-list {
  margin-bottom: 24px;
}

.uf-list ul {
  text-align: center;
  padding-left: 0;
  list-style-type: none;
}

.uf-list ul li {
  padding: 16px;
  display: inline-block;
}

#map {
  height: 100%;
  min-height: 200px;
  min-width: 100%;
}

.footer {
  margin-top: 12px;
  padding: 26px 20px;
}

.footer nav {
  justify-content: center;
  gap: 28px;
  display: flex;
}

.footer nav a {
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.footer nav a:hover {
  text-decoration: underline;
}

@media (max-width: 420px) {
  body {
    padding: 0;
  }

  .site-header nav .links {
    display: none;
  }

  .site-header nav.open {
    z-index: 90000;
    display: block !important;
  }

  .site-header nav.open .links {
    background: #fff;
    flex-direction: column;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 10px #0003;
  }

  .site-header nav.open .links a {
    margin: 0;
    padding: 18px 32px;
    color: #111 !important;
  }

  .site-header nav.open .links a.outline {
    border: none;
  }

  .site-header nav .menu-icon {
    display: block;
  }

  .recent-search-item {
    width: 100%;
  }

  .f-row {
    flex-direction: column;
    row-gap: 24px;
  }

  .footer nav {
    flex-direction: column;
  }

  .main-content .card {
    flex-direction: column;
    gap: 16px;
    padding: 24px;
  }

  .main-content .card div {
    flex: 1 1 0;
  }

  .main-content .examples, .main-content .libs {
    max-width: 100%;
  }

  .main-content .example {
    max-height: 300px;
    min-width: auto;
    color: #fff;
    background: #111;
    padding: 16px;
    overflow-y: scroll;
  }

  .listing-content {
    flex-direction: column;
    gap: 32px;
    display: flex;
  }

  #map {
    margin-top: -20px;
  }
}

/*# sourceMappingURL=style.css.map */
