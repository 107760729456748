// dark mode
$theme-dark-background-color: #000;
$theme-dark-background-color-inverted: #fff;
$theme-dark-primary-text-color: #fff;
$theme-dark-secondary-text-color: #ddd;
$theme-dark-secondary-text-color-inverted: #333;


// white mode
$theme-white-background-color: #fff;
$theme-white-background-color-inverted: #000;
$theme-white-primary-text-color: #111;
$theme-white-secondary-text-color: #333;
$theme-white-secondary-text-color-inverted: #ddd;



// breakpoints
$mobile-breakpoint: 420px;


$accent-color: #ff4365;
$default-border-radius: 10px;

html {
    margin: 0;
}

// typography
h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
}

body {
    max-width: 1298px;
    margin: 0 auto;
    font-family: Roboto, sans-serif;
    padding: 0 35px;
    background: url('/static/header-bg.svg');
    background-repeat: no-repeat;
    background-position: center -10px;
    transition: background-color 200ms ease-in;

    &.theme-white {
        color: $theme-white-primary-text-color;
        background-color: $theme-white-background-color;
    }

    &.theme-dark {
        color: $theme-dark-primary-text-color;
        background-color: $theme-dark-background-color;
    }
}

// themes
.theme-white {

    h1,
    h2,
    h3,
    h4,
    a {
        color: $theme-white-primary-text-color;
    }

    .site-header {
        .logo {
            color: $theme-white-primary-text-color;
        }
    }

    #theme-btn {
        color: $theme-white-primary-text-color;
    }

    nav {
        a {
            color: $theme-white-primary-text-color;
        }

        .menu-icon {
            color: $theme-white-primary-text-color;
        }
    }

    .hero {
        button {
            color: $theme-white-primary-text-color;
        }
    }


    .home-section {
        .section-title {
            .title-with-icon {
                h2 {
                    color: $theme-white-primary-text-color;
                }
            }

            .section-subtitle {
                color: $theme-white-secondary-text-color;
            }
        }
    }

    .recent-searches {
        .recent-search-item {
            background-color: rgba(255, 255, 255, 0.2);
            border: 1px solid #eee;

            .recent-search-item-title {
                .recent-search-item-badges {
                    color: rgba($theme-white-primary-text-color, 0.75);
                }
            }
        }
    }

    .main-content {
        .card {
            background-color: rgba(255, 255, 255, 0.85);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }
}


.theme-dark {

    h1,
    h2,
    h3,
    h4,
    a {
        color: $theme-dark-primary-text-color;
    }

    .site-header {
        .logo {
            color: $theme-dark-primary-text-color;
        }
    }

    #theme-btn {
        color: $theme-dark-primary-text-color;
    }

    nav {
        a {
            color: $theme-dark-primary-text-color;
        }

        .menu-icon {
            color: $theme-dark-primary-text-color;
        }
    }

    .hero {
        button {
            color: $theme-dark-primary-text-color;
        }
    }

    .home-section {
        .section-title {
            .title-with-icon {
                h2 {
                    color: $theme-dark-primary-text-color;
                }
            }

            .section-subtitle {
                color: $theme-dark-secondary-text-color;
            }
        }
    }

    .recent-searches {
        .recent-search-item {
            background-color: rgba(0, 0, 0, 0.2);
            border: 1px solid #333;

            .recent-search-item-title {
                .recent-search-item-badges {
                    color: rgba($theme-dark-primary-text-color, 0.75);
                }
            }
        }
    }

    .main-content {
        .card {
            background-color: #111;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        }
    }

    .pagination {
        a {
            color: $accent-color !important;
            border: 1px solid #999;
            color: #ddd !important;

            &.current {
                border: 1px solid #fff;
                color: white !important;
            }
        }
    }
}

h1,
h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.t-center {
    text-align: center;
}

.icon {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

}

.site-header {
    height: 38px;
    margin-top: 12px;
    margin-bottom: 32px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 22px;
        text-decoration: none;
        flex-grow: 100;
    }

    #theme-btn {
        border: none;
        background: none;
        cursor: pointer;
        padding: 8px;
    }

    nav {
        flex-grow: 0;

        a {
            text-decoration: none;
            margin: 8px 28px;
            box-sizing: border-box;
            font-size: 16px;
            transition: color 200ms ease-in;

            &:last-child {
                margin-right: 0;
            }

            &.outline {
                border: 1px solid $accent-color;
                padding: 8px 18px;
                border-radius: $default-border-radius;
            }


            &:hover,
            &:active {
                color: $accent-color;
            }
        }

        .menu-icon,
        .close-menu-icon {
            border: none;
            background: none;
            cursor: pointer;
            padding: 4px;
            font-size: 34px;
            display: none;
        }
    }
}

.hero {
    max-width: 620px;
    margin: 92px auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    h1 {
        text-align: center;
        margin-bottom: 54px;
        font-size: 32px;
        line-height: 48px;
    }
}

#searchbox {
    position: relative;
    background: white;
    border-radius: $default-border-radius;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    outline: 2px solid transparent;
    transition: outline 200ms ease-in, box-shadow 200ms ease-in;

    .search-field {
        display: flex;
        flex-direction: row;
    }

    &:hover {
        outline: 2px solid rgba($accent-color, 0.5);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

        #search-result {
            outline: 2px solid rgba($accent-color, 0.5);
        }
    }

    input {
        padding: 18px 48px 18px 24px;
        border: none;
        box-sizing: border-box;
        font-size: 16px;
        min-width: 100%;
        outline: none;
        background: none;

        &::placeholder {
            opacity: 0.7;
        }
    }

    button {
        margin-left: -48px;
        vertical-align: middle;
        border: none;
        background: none;
        cursor: pointer;
        color: #000 !important;
    }


    #search-result {
        width: 100%;
        position: absolute;
        box-sizing: border-box;
        background: white;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.45);
        border-radius: 0 0 $default-border-radius $default-border-radius;
        transition: outline 200ms ease-in, box-shadow 200ms ease-in;
        z-index: 1000;

        .search-item {
            display: block;
            border-bottom: 1px dashed #ddd;
            padding: 20px 20px 10px 20px;
            color: black;
            text-decoration: none;
            display: block;

            &:last-child {
                border-bottom: none;
            }
        }

        p {
            color: black;
            margin: 0 0 5px 0;

            &.municipio-uf {
                font-size: 13px;
                opacity: 0.8;
            }
        }
    }


    &.with-results {
        border-radius: $default-border-radius $default-border-radius 0 0;
    }

    &.no-results {
        #search-result {
            display: none
        }
    }
}


.home-section {
    margin-bottom: 12px;

    .section-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        padding: 0 20px;

        .title-with-icon {
            display: flex;
            align-items: center;
            gap: 10px;

            h2 {
                font-size: 18px;
                font-weight: 600;
                width: 100%;
            }
        }

        .section-subtitle {
            margin: 10px 0;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
        }
    }

    .content {
        padding: 0 20px;
    }
}

.recent-searches {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 28px;
    row-gap: 10px;
    margin-bottom: 36px;

    .recent-search-item {
        padding: 16px;
        box-sizing: border-box;
        min-width: 45%;
        max-width: 390px;


        .recent-search-item-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                font-size: 14px;

                a {
                    text-decoration: none;
                }
            }

            .recent-search-item-badges {
                font-size: 12px;

                span {
                    display: inline-block;
                    padding: 2px 4px;
                }
            }
        }

        p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 8px 0 0 0;
            font-size: 14px;
        }
    }
}


.flex {
    display: flex;
}

.f-row {
    flex-direction: row;
    column-gap: 48px;
}


.f-column {
    flex-direction: column;
    row-gap: 24px;
}

.stats-section {
    img {
        height: auto;
        width: 100%;
    }
}

.stats {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin: 0;

    .stats-number {
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        display: block;
    }
}


.github-repos {
    display: flex;
    flex-wrap: wrap;
    max-width: 660px;
    margin-top: -12px;
    column-gap: 12px;

    .github-repo {
        position: relative;
        flex-grow: 2;
        border-bottom: 1px dotted #999;
        padding: 20px 20px;

        h3 {
            margin: 2px 0 8px 0;

            a {
                // color: $primary-text-color;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                font-size: 16px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .lang {
            // color: $secondary-text-color;

            &::before {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 6px;
                border-radius: 100%;
                background-color: gray;
            }
        }

        .lang.rs::before {
            background-color: #C28F0A;
        }

        .lang.py::before {
            background-color: #1D67D6;
        }

        .lang.js::before {
            background-color: #FFF500;
        }

        .lang.go::before {
            background-color: #0E9CFF;
        }
    }
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}

.main-content {

    h1,
    h2,
    h3 {
        font-weight: 600;
    }

    h1 {
        font-size: 18px;
    }

    h2 {
        font-size: 22px;
        margin-bottom: 8px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
    }

    p,
    ul {
        margin-top: 8px;
        margin-bottom: 18px;
        line-height: 1.3em;
        font-size: 15px;
    }

    ul li {
        margin-bottom: 8px;
    }

    .card {
        display: flex;
        margin-bottom: 16px;
        padding: 36px;
        box-sizing: border-box;
        gap: 48px;

        div {
            flex: 1 1 0;
        }


        &.listing-page {
            flex-direction: column;
            gap: 18px;
        }
    }

    .example {
        max-height: 300px;
        min-width: 100%;
        overflow: scroll;
        background: #111;
        color: white;
        padding: 16px;
    }

    .example {
        max-width: 400px;
    }
}

.listing-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, auto);
    gap: 18px;

    .listing-item {
        a {
            font-weight: 500;
        }

        p {
            margin: 0;

        }
    }
}

.breadcrumb {
    ol {
        list-style: none;
        margin: 0 0 4px 0;
        padding-left: 0;
        list-style-position: inside;
        display: inline-block;
    }

    li {
        padding: 0;
        display: inline-block;

        &:after {
            content: " ›";
        }

        &:last-child {
            &:after {
                content: "";
            }
        }

        a {
            text-decoration: none;
            min-width: 48px;
            display: inline-block;
            text-align: center;
            min-height: 48px;
            box-sizing: border-box;
            padding: 8px;
        }
    }
}

.pagination {
    a {
        display: inline-block;
        padding: 8px 11px;
        border: none;
        border: 1px solid #ddd;
        color: $accent-color !important;
        text-decoration: none;

        &.current {
            border: 1px solid #999;
            color: #111 !important;
        }
    }
}

.uf-list {
    margin-bottom: 24px;

    ul {
        padding-left: 0;
        list-style-type: none;
        text-align: center;

        li {
            display: inline-block;
            padding: 16px;
        }
    }
}

#map {
    height: 100%;
    min-height: 200px;
    min-width: 100%;
    // background: red;
}

.footer {
    margin-top: 12px;
    padding: 26px 20px;

    nav {
        display: flex;
        justify-content: center;
        gap: 28px;

        a {
            font-size: 14px;
            display: block;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: $mobile-breakpoint) {
    body {
        padding: 0;
    }

    .site-header {
        nav {
            .links {
                display: none;
            }

            &.open {
                display: block !important;
                z-index: 90000;

                .links {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: white;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

                    a {
                        padding: 18px 32px;
                        margin: 0;
                        color: $theme-white-primary-text-color !important;

                        &.outline {
                            border: none;
                        }
                    }
                }
            }

            .menu-icon {
                display: block;
            }
        }
    }

    .recent-search-item {
        width: 100%;
    }

    .f-row {
        flex-direction: column;
        row-gap: 24px;
    }

    .footer {
        nav {
            flex-direction: column;
        }
    }

    .main-content {
        .card {
            padding: 24px;
            flex-direction: column;
            gap: 16px;

            div {
                flex: 1 1 0;
            }
        }

        .examples,
        .libs {
            max-width: 100%;
        }

        .example {
            max-height: 300px;
            min-width: auto;
            overflow-y: scroll;
            background: #111;
            color: white;
            padding: 16px;
        }
    }

    .listing-content {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    #map {
        margin-top: -20px;
    }
}